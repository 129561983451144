import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const ManufacturingPage = () => {
  return (
    <Layout hero={<HeroImage title="Manufacturing" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                Orrcon Steel has two Australian manufacturing sites - A structural tubular mill in Brisbane and a precision tubing mill in Adelaide.
              </p>
              <p>
                Our manufacturing processes meet the highest standards and quality assurance including ISO 9001.
              </p>
              <p>
                Whether it's specialised tight tolerance, high bending quality Precision Tube, 
                corrosion resistant in-line Electro Galvanised ALLGAL Tubulars (with Zinc both inside and outside the Section) or 
                high grade steel API Linepipe for high pressure gas pipelines, 
                when you contact Orrcon Steel for advice or assistance you get to the right person with the right answers.
              </p>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col sm={6}>
              <ul>
                <li>
                  <p>Our Brisbane Mill manufactures plain, ready primed and Zinc coated Electric Resistance Welded structural steel.</p>
                </li>
                <li>
                  <p>Our structural tubular products are a significant part of our production and are used across a wide range of structural applications from building structures to major sporting arenas, major mining and industry infrastructure and for aesthetic structural use such as airports and shipping terminals.</p>
                </li>
              </ul>
            </Col>
            <Col sm={6}>
              <ul>
                <li>
                  <p>Orrcon Steel manufactures precision tube at its precision tubing mill in Adelaide. Orrcon Steel's precision products are manufactured to comply with AS1450.</p>
                </li>
                <li>
                  <p>Our Structural Tubulars, including CHS, SHS and RHS are manufactured to the Australian Standard for structural steel hollow section AS/NZS 1163 and cover steel grades C250L0, C350L0 and C450L0. For further information visit our structural products.</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">
              We support local communities by providing
            </h1>
          </div>
          <LinkedList items={[
            {
              content: <LinkedListItem 
                title="DISTRIBUTION"
                content="" />,
              path: '/community/distribution'
            },
            {
              content: <LinkedListItem 
                title="RANGE"
                content="" />,
              path: '/community/range'
            },
            {
              content: <LinkedListItem 
                title="MANUFACTURING"
                content="" />,
              path: '/community/manufacturing'
            }
          ]} />
        </Col>
      </Row>
    </Layout>
  )
}

export default ManufacturingPage

export const Head = () => <title>Manufacturing | Orrcon Steel</title>
